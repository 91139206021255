import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"
import { scroller } from "react-scroll"

import { NavButton, NavButtonLink } from "../elements/buttons"

const NavContainer = styled.nav`
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const NavWrapper = styled.ul`
  max-width: 400px;
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: 0;

  li {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const scrollToElements = name =>
  scroller.scrollTo(name, {
    activeClass: "active",
    spy: true,
    smooth: true,
    offset: -25,
    duration: 650,
  })
const Nav = () => {
  let navigation
  const windowPathName =
    typeof window !== "undefined" && window.location.pathname

  if (windowPathName !== "/") {
    navigation = (
      <NavContainer>
        <NavWrapper>
          <li>
            <NavButtonLink to="/">Strona główna</NavButtonLink>
          </li>
        </NavWrapper>
      </NavContainer>
    )
  } else {
    navigation = (
      <NavContainer>
        <NavWrapper>
          <li>
            <NavButton onClick={scrollToElements.bind(this, "about")}>
              O nas
            </NavButton>
          </li>
          <li>
            <NavButton onClick={scrollToElements.bind(this, "offer")}>
              Oferta
            </NavButton>
          </li>
          {/* <li>
          <NavButton onClick={scrollToElements.bind(this, "projects")}>
            Projekty
          </NavButton>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li> */}
          <li>
            <NavButton onClick={scrollToElements.bind(this, "contact-form")}>
              Kontakt
            </NavButton>
          </li>
        </NavWrapper>
      </NavContainer>
    )
  }

  return navigation
}

export default Nav
