import React from "react"
import styled from "styled-components"
import logo from "../../images/jms-logo.svg"
// import SocialBar from "./socialBar"
import Nav from "../nav"

const FooterWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    font-size: 20px;
    text-align: center;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 231px;
  height: auto;
  margin: 0;
`
const FooterTextBox = styled.div`
  margin-top: 30px;
  font-size: 13px;
  max-width: 495px;
  text-align: center;
  p {
    margin: 0;
  }
`

const Caption = styled.div`
  margin-top: 30px;
  font-size: 12px;
`

const Footer = () => {
  return (
    <footer>
      <FooterWrapper>
        <LogoWrapper>
          <LogoImage src={logo} alt="Logo JMS Rail Systems"></LogoImage>
          <span>Tworzymy kolej z przyszłością</span>
        </LogoWrapper>
        <FooterTextBox>
          <p>
            Tworzymy kompleksowe opracowania w zakresie sterowania ruchem
            kolejowym.
          </p>
        </FooterTextBox>
        <Nav />
        {/* <SocialBar /> */}
        <Caption>
          <p>
            © {new Date().getFullYear()}, Created by
            {` `}
            <a href="https://www.weyer.tech">weyer.tech</a>
          </p>
        </Caption>
      </FooterWrapper>
    </footer>
  )
}

export default Footer
