import React from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from "react-spring"

import Header from "./header"
import Footer from "./footer/footer"
import GlobalStyle from "../GlobalStyle"

const Layout = ({ children }) => {
  const fade = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
  })
  return (
    <>
      <GlobalStyle />
      <Header />
      <animated.main style={fade}>{children}</animated.main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
