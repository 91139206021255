import { Link } from "gatsby"
import styled from "styled-components"
import React, { useState } from "react"

import { FaEnvelope } from "react-icons/fa"
import { ToggleButton } from "../elements/buttons"
import { fixedTop } from "../util/position"
import logo from "../images/jms-logo.svg"
import SideDrawer from "./sideDrawer"
import Nav from "./nav"

const HeaderWrapper = styled.div`
  z-index: 999;
  background: white;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  padding: 15px 16px;
  ${fixedTop}
  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    position: relative;
    padding-bottom: 0;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    padding: 20px 40px;
    padding-bottom: 0;
    position: relative;
  }

  .text-box {
    grid-row: span 2;
    display: none;
    flex-direction: column;
    text-align: center;
    @media (min-width: 992px) {
      display: flex;
    }
    span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      a {
        text-decoration: none;
        color: inherit;
        transition: all 0.1s ease-in-out;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`

const LogoWrapper = styled(Link)`
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;

  img {
    width: 100%;
    max-width: 231px;
    height: auto;
  }

  @media (min-width: 480px) {
    justify-self: center;
  }
`

const NavWrapper = styled.div`
  width: 100%;
  background: white;
  position: sticky;
  top: 0;
  z-index: 100;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

const Header = () => {
  const [isOpen, setDrawerOpen] = useState(false)
  return (
    <>
      <header>
        <SideDrawer show={isOpen} clickHandler={() => setDrawerOpen(!isOpen)} />
        <div className="container">
          <HeaderWrapper top="0" left="0">
            <ToggleButton
              top="30"
              onClick={() => setDrawerOpen(!isOpen)}
              show={isOpen}
              name="menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </ToggleButton>
            <div className="text-box">
              <span>Tworzymy kolej z przyszłością</span>
            </div>
            <LogoWrapper to="/">
              <img src={logo} alt="Logo JMS Rail Systems" />
            </LogoWrapper>
            <div
              className="text-box"
              itemScope
              itemType="http://schema.org/LocalBusiness"
            >
              <span>
                <a href={`mailto:kontakt@jmsrail.com`}>kontakt@jmsrail.com</a>
                <FaEnvelope style={{ marginLeft: "1rem" }} />
              </span>
              {/* <span>
                <a href={`tel:+48531759555`} itemProp="telephone">
                  531 759 555
                </a>
                <FaPhone style={{ marginLeft: "1rem" }} />
              </span> */}
            </div>
          </HeaderWrapper>
        </div>
      </header>
      <NavWrapper>
        <Nav />
      </NavWrapper>
    </>
  )
}

export default Header
