import React from "react"
import Nav from "./nav"
import { animated, useSpring } from "react-spring"
// import SocialBar from "./footer/socialBar"
import styled from "styled-components"

import { FaEnvelope } from "react-icons/fa"

const SideDrawerWrapper = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: ${props => (props.show ? "all" : "none")};
  z-index: 998;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      :hover {
        text-decoration: underline;
      }
    }
  }
`

const SideDrawer = ({ show, clickHandler }) => {
  const { x } = useSpring({
    x: show ? 0 : 100,
  })
  return (
    <SideDrawerWrapper show={show} onClick={clickHandler}>
      <animated.div
        style={{
          height: "80%",
          background: "#776bfff7",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          overflow: "scroll",
          color: "white",
          transform: x.interpolate(x => `translate3d(0,-${x}%,0)`),
        }}
      >
        <Nav />
      </animated.div>
      <animated.div
        style={{
          height: "20%",
          background: "#f8f7f2",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          transform: x.interpolate(x => `translate3d(0,${x}%,0)`),
        }}
      >
        <div>
          <span>Tworzymy kolej z przyszłością</span>
        </div>
        {/* <SocialBar /> */}
        <ContactInfo
          className="text-box"
          itemScope
          itemType="http://schema.org/LocalBusiness"
        >
          <span>
            <FaEnvelope style={{ marginRight: "1rem" }} />
            <a href={`mailto:kontakt@jmsrail.com`} itemProp="email">
              kontakt@jmsrail.com
            </a>
          </span>
          {/* <span>
            <FaPhone style={{ marginRight: "1rem" }} />
            <a href={`tel:+48531759555`} itemProp="telephone">
              531 759 555
            </a>
          </span> */}
        </ContactInfo>
      </animated.div>
    </SideDrawerWrapper>
  )
}

export default SideDrawer
