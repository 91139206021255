import React from "react"
import styled from "styled-components"

const SectionContainer = styled.section`
  padding: 40px 0;
  @media (min-width: 768px) {
    padding: 60px 0;
  }
  @media (min-width: 992px) {
    padding: 75px 0;
  }
`

const MottoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;
  @media (min-width: 480px) {
    padding: 0 24px;
  }
  @media (min-width: 768px) {
    padding: 0 32px;
  }
  @media (min-width: 992px) {
    padding: 0 40px;
  }
`

const MottoContentWrapper = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 620px;
  }
  @media (min-width: 992px) {
    max-width: 840px;
  }
`

const HeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  ::before,
  ::after {
    content: "";
    width: 100%;
    height: 1px;
    flex: 5 1;
    flex-shrink: 1;
    background-color: #776bff;
    opacity: 0.3;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`

const MottoHeading = styled.h2`
  font-size: 26px;
  margin: 0;
  text-align: center;
  flex-grow: 1;

  @media (min-width: 992px) {
    font-size: 32px;
  }
`
const MottoContentContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #776bff;
    bottom: 0;
    opacity: 0.3;
  }

  @media (min-width: 992px) {
    margin-top: 40px;
    padding-bottom: 40px;
  }

  blockquote {
    text-align: center;
    min-width: 250px;
    margin: 0 auto;
    padding: 0 15px;
    font-size: 18px;
    @media (min-width: 768px) {
      max-width: 560px;
      font-size: 20px;
    }
    @media (min-width: 992px) {
      max-width: 790px;
      font-size: 22px;
    }
  }
`

const Motto = () => {
  return (
    <SectionContainer>
      <MottoContainer>
        <MottoContentWrapper>
          <HeaderWrapper>
            <MottoHeading>Nasze cele, wartości i zobowiązania</MottoHeading>
          </HeaderWrapper>
          <MottoContentContainer>
            <blockquote>
              Bezpieczeństwo. Efektywność techniczna i ekonomiczna. Zaufanie.
            </blockquote>
          </MottoContentContainer>
        </MottoContentWrapper>
      </MottoContainer>
    </SectionContainer>
  )
}

export default Motto
