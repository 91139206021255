import styled from "styled-components"
import { Link } from "gatsby"

import { fixedTop } from "../util/position"

export const Button = styled.button`
  font-weight: 500;
  padding: 12px 24px;
  border-radius: 4px;
  color: inherit;
  font-size: 14px;
  border: 1px solid #685cea;
  background: transparent;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  margin: 7.5px 0;
  line-height: 1.2;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @media (min-width: 480px) {
    width: auto;
    max-width: 270px;
  }
`

export const ButtonLink = styled(Link)`
  font-weight: 500;
  display: block;
  padding: 12px 24px;
  border-radius: 4px;
  color: inherit;
  font-size: 14px;
  border: 1px solid #685cea;
  background: transparent;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  margin: 7.5px 0;
  line-height: 1.2;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @media (min-width: 480px) {
    width: auto;
    max-width: 270px;
  }
  :hover {
    background: #685cea;
    color: white;
    box-shadow: 0 18px 40px -12px rgba(119, 107, 225, 0.9),
      0 10px 10px -12px rgba(119, 107, 225, 0.9);
  }
`

export const NavButton = styled(Button)`
  font-weight: 500;
  border: none;
  margin: 0;
  font-size: 18px;
  padding: 12px;
`

export const NavButtonLink = styled(Link)`
  font-weight: 500;
  text-align: center;
  display: block;
  padding: 12px;
  color: inherit;
  font-size: 18px;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  margin: 0;
  line-height: 1.2;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @media (min-width: 480px) {
    width: auto;
    max-width: 270px;
  }
`

export const ToggleButton = styled(Button)`
  height: 24px;
  width: 30px;
  border: none;
  outline: none;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  pointer-events: auto;
  ${fixedTop}
  @media (min-width: 768px) {
    display: none;
  }

  span {
    width: 30px;
    height: 3px;
    background: #1b1b1b;
    transition: all 0.25s ease-in-out 0s;
  }

  span:nth-child(1) {
    transform: ${props =>
      props.show ? "translateY(8px) rotate(45deg)" : "none"};
  }
  span:nth-child(2) {
    transform: ${props => (props.show ? "scaleX(0)" : "none")};
    transition: transform 0.9s;
  }
  span:nth-child(3) {
    transform: ${props =>
      props.show ? "translateY(-8px) rotate(-45deg)" : "none"};
  }
`

export const PrimaryButton = styled(Button)`
  background: #685cea;
  color: white;
  :hover {
    box-shadow: 0 18px 40px -12px rgba(119, 107, 225, 0.9),
      0 10px 10px -12px rgba(119, 107, 225, 0.9);
  }
`

export const FormSubmitBtn = styled(PrimaryButton)`
  @media (min-width: 480px) {
    max-width: 100%;
    width: 100%;
  }
`
